import dashboard from './dashboard'
import bbook from './bbook'

export default [
  {
    icon: ['far', 'ufo'],
    title: 'Dashboard ',
    route: 'dashboard-ecommerce',
  },
  // {
  //   icon: ['far', 'chart-bar'],
  //   title: 'VaR Analysis',
  //   route: 'var-analysis',
  // },
  ...bbook,
  // {
  //   icon: ['far', 'bolt'] ,
  //   title: 'Stress Test',
  //   route: 'stress-test',
  // },
  // {
  //   icon: ['fas', 'shield-alt'],
  //   title: 'Credit Risk',
  //   route: 'credit-risk',
  // },
  {
    icon: ['far', 'file-chart-line'],
    title: 'Reports',
    route: 'reports',
  },
  // {
  //   icon: ['far', 'siren-on'],
  //   title: 'Alerts',
  //   route: 'alerts',
  // },
  {
    icon: ['far', 'cog'],
    title: 'Settings',
    route: 'settings-group-management',
  },
]