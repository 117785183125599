<template>
  <p class="clearfix mb-0 text-center">
    <span class="d-block d-md-inline-block mt-25" :style="{color: '#b0b3b8'}">
      COPYRIGHT  © {{ new Date().getFullYear() }} by EAERA LTD. All rights Reserved
    </span>
  </p>
</template>

<script>
// import { BLink } from 'bootstrap-vue'

export default {
  components: {
    // BLink,
  },
}
</script>
