export default [
    {
        header: 'B-Book',
        icon: ['far', 'chess-king-alt'],
        children: [
            {
                title: 'Market Exposure',
                route: 'market-exposure',
            },
            {
                title: 'User Stats',
                route: 'user-stats',
            },
            {
                title: 'Company P/L',
                route: 'company-p-l',
            },
            {
                title: 'Deep Dive',
                route: 'deep-dive',
            },
        ],
    },
]
